<script lang="ts" setup>
useHead({
  title: useNuxtApp().$i18n.t('appNameForTitle'),
})
</script>

<template>
  <div class="ShopModule relative font-myriad">
    <ShopLayoutNavbar class="PagePadding" />
    <slot />
  </div>
</template>

<style>
.ShopModule .PagePadding {
    @apply px-3 md:px-8 lg:px-12 xl:px-20 2xl:px-40
}
</style>
